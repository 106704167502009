<template>
  <div>
      <div class="content">
          <div class="title">
              <div class="content-view">
                  <div class="name">{{item.name}}</div>
                  <div class="phone">{{item.phone}}</div>
                  <div class="isDefault" v-if="item.isDefault==0" :style="{backgroundColor:isSkin}">默认地址</div>
              </div>
              <div class="address">{{item.address}}</div>
          </div>
          <div class="editor" :style="{backgroundColor:isSkin}" @click.stop="editorMethod(item)">编辑</div>
          <div class="deletes" @click.stop="deletesMethod(item)">删除</div>
      </div>
  </div>
</template>

<script>
export default {
    props:{
        item:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    },
    computed:{
        isSkin(){
            return this.$store.state.isSkin
        },
    },
    methods:{
        editorMethod(index){
            this.$emit('editorMethod', index)
        },
        deletesMethod(index){
            this.$emit('deletesMethod', index)
        }
    }
}
</script>

<style scoped lang="scss">
.content{
    width: 100%;
    height: 90px;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 4px;
    margin-top: 20px;
    display: flex;
    justify-items: center;
    align-items: center;
    .title{
        width: 75%;
        height: 50px;
        margin-left: 20px;
        .content-view{
            display: flex;
            justify-items: center;
            align-items: center;
            height: 20px;
            .name{
                font-size: 14px;
                line-height: 20px;
                color: #333333;
            }
            .phone{
                font-size: 12px;
                line-height: 17px;
                color: #999999;
                margin-left: 10px;
            }
            .isDefault{
                width: 68px;
                height: 21px;
                border-radius: 4px;
                font-size: 12px;
                line-height: 21px;
                text-align: center;
                color: #FFFFFF;
                margin-left: 10px;
            }
        }
        .address{
            height: 20px;
            font-size: 12px;
            line-height: 20px;
            color: #666666;
            margin-top: 10px;
        }
    }
    .editor{
        width: 72px;
        height: 42px;
        background-color: wheat;
        border-radius: 4px;
        font-size: 16px;
        line-height: 42px;
        color: #FFFFFF;
        text-align: center;
        margin-left: 20px;
        cursor: pointer;
    }
    .deletes{
        width: 72px;
        height: 42px;
        font-size: 16px;
        color: #666666;
        border: 1px solid #DDDDDD;
        box-sizing: border-box;
        line-height: 42px;
        text-align: center;
        border-radius: 4px;
        margin-left: 20px;
        cursor: pointer;
    }
}
</style>