<template>
  <div class="main-contents">
    <!-- 头 -->
    <div class="top" :style="{backgroundColor:isSkin}">
      <div class="thumb-title">我的地址</div>
    </div>
    <!-- 新增 -->
    <div class="add-view" :style="{backgroundColor:isSkin}" @click="addMethod()">
      <img src="../../../assets/address/addressimg.png" alt="">
    </div>
    <div class="list">
      <addressList v-for="(item,index) in list" :key="index" :item="item" @editorMethod="editorMethod" @deletesMethod="deletesMethod"></addressList>
      <div class="page" v-show="pageShow">
            <div class="page1">
                <el-pagination background :current-page="currentPage"
                  :page-size="1" layout="prev, pager, next" :total="totals" @current-change="handleCurrentChange">
                </el-pagination>
            </div>
          </div>
    </div>
    <el-dialog title="新增/编辑地址" class="dialog" :width="'488px'" :visible.sync="dialogFormVisible" :close-on-click-modal=false @close="closeMethod()">
      <div class="name">收货人</div>
      <input class="input-view" v-model="name" placeholder="请输入收货人">
      <div class="phone">联系方式</div>
      <input class="input-view" v-model="phone" placeholder="请输入联系方式">
      <div class="address">详细地址</div>
      <input class="input-view" v-model="address" placeholder="请输入详细地址">
      <div class="isDefault">
        <div class="isDefault-view">默认地址</div>
        <div class="isDefault-switch">
          <el-switch
            v-model="isDefault"
            :active-color= isSkin
            :inactive-color="''"
            @change="changeMethod">
          </el-switch>
        </div>
      </div>
       <div class="sure" :style="{backgroundColor:isSkin}" @click="sureMethod()">确认</div>
    </el-dialog>
  </div>
</template>

<script>
import {Message} from 'element-ui'
import addressList from './box/addressList-box'
export default {
  components:{addressList},
  data() {
    return {
      pageShow:false,
      totals:1,
      currentPage:1,
      pageSize:5,
      dialogFormVisible:false,
      list:[],
      name:'',
      phone:'',
      address:'',
      isDefault:false,
      ID:'',
      default:1
    }
  },
  computed:{
    isSkin(){
		  return this.$store.state.isSkin
    },
  },
  created(){
    this.handleCurrentChange(1)
    this.getMemberAddress()
  },
  methods:{
    /**地址列表**/
    getMemberAddress(){
      var that = this
      that.$api.getMemberAddress({
        params:{
          pageNum:that.currentPage,
          pageSize:that.pageSize
        }
      }).then(res=>{
        if (res.data.code == 0) {
          that.list = res.data.data.list
          this.totals = res.data.data.pages
          if (res.data.data.list.length == 0){
            that.pageShow = false
          }else {
            that.pageShow = true
          }
        }else{
          that.list = []
          that.pageShow = false
        }
      })
    }, 
    /**新增/修改地址**/ 
    getAddMemberAddress(){
      var that = this
      that.$api.getAddMemberAddress({
        id:that.ID,
        address:that.address,
        name:that.name,
        phone:that.phone,
        isDefault:that.default
      }).then(res=>{
        if (res.data.code == 0) {
          Message({
            showClose: true,
            message: res.data.msg,
            type: 'success'
          })
          this.getMemberAddress()
        }else{
          Message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
          this.getMemberAddress()
        }
      })
    },
    /**新增地址**/
    addMethod(){
      this.dialogFormVisible = true
      this.name = ''
      this.phone = ''
      this.address = ''
      this.isDefault = false
      this.ID = ''
    },
    /**关闭弹窗**/ 
    closeMethod(){

    },
    /**是否默认**/
    changeMethod(index){
      this.isDefault = index
      if (this.isDefault) {
        this.default = 0
      }else{
        this.default = 1
      }
    }, 
    /**当前页按钮**/ 
    handleCurrentChange(index) {
      this.currentPage = index;
      this.getMemberAddress()
      this.$nextTick(function () {
        /**更换分页按钮皮肤**/
        var array = document.querySelectorAll(".el-pagination.is-background .el-pager li"); 
        if(array && array.length>0){
          array.forEach((item,index1) => {
            if (index == array[index1].innerText) {
              array[index1].style.background  = this.isSkin;
            }else{
              array[index1].style.background  = "#f4f4f5";
            }
          })
        }
      });
    },
    /**编辑**/
    editorMethod(index){
      this.dialogFormVisible = true
      this.name = index.name
      this.phone = index.phone
      this.address = index.address
      this.default = index.isDefault
      this.ID = index.id
      if (index.isDefault == 0) {
        this.isDefault = true
      }else{
        this.isDefault = false
      }
    },
    getDelAddres(index){
      var that = this
      that.$api.getDelAddres({
        params:{
          id:index
        }
      }).then(res=>{
        if (res.data.code == 0) {
          Message({
            showClose: true,
            message: res.data.msg,
            type: 'success'
          })
          this.getMemberAddress()
        }else{
          Message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
          this.getMemberAddress()
        }
      })
    },
    /**删除**/ 
    deletesMethod(index){
      console.log(index);
      this.$confirm('点击“确认”后，您将删除这条信息，确认删除吗？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then((res) => {
        console.log(res);
          this.getDelAddres(index.id)
      }).catch((err) => {
        console.log(err);
      });
    },
    /**确认按钮**/
    sureMethod(){
      this.dialogFormVisible = false
      this.getAddMemberAddress()
    }
  }
}
</script>

<style scoped lang="scss">
.main-contents{
  width: 100%;
  .top{
    width: 100%;
    height: 55px;
    border-radius: 4px;
    line-height: 55px;
    .thumb-title{
      font-size: 18px;
      color: #FFFFFF;
      margin-left: 12px;
    }
  }
  .add-view{
    width: 122px;
    height: 40px;
    border-radius: 4px;
    margin-top: 20px;
    display: flex;
    justify-items: center;
    align-items: center;
    cursor: pointer;
    > img{
      margin-left: 20px;
    }
  }
  .list{
    width: 100%;
    .page{
      width: 896px;
      padding: 30px 20px 30px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .page1{
        display: flex;
        justify-items: center;
        align-items: center;
      }
    }
  }
  .name{
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #333333;
    margin-top: -10px;
  }
  .phone{
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #333333;
    margin-top: 20px;
  }
  .address{
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #333333;
    margin-top: 20px;
  }
  .isDefault{
    display: flex;
    justify-items: center;
    align-items: center;
    margin-top: 20px;
    .isDefault-view{
      font-size: 16px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #333333;
    }
    .isDefault-switch{
      width: 50px;
      height: 23px;
      margin-left: 10px;
    }
  }
  .sure{
    width: 76px;
    height: 36px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 36px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 40px;
    margin-left: 372px;
    cursor: pointer;
  }
}
.input-view{
  width: 448px;
  height: 40px;
  background: #FCFCFC;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 10px;
  font-size: 15px;
  line-height: 40px;  
  color: #666666;
  outline: none;
  margin-top: 10px;
}
</style>